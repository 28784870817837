
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ScreenshotCarousel extends Vue {
  mounted(){
    (<any>$('.carousel')).carousel({
      interval: 5000
    })
  }
}
