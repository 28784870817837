
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class SaioOverallItemsMobile extends Vue {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  mounted() {
    (window as any).jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0,
    });
  }
}
