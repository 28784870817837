
import { Component, Vue } from 'vue-property-decorator';
import Processes from '@/components/Processes.vue';
import WantKnowMore from '@/components/WantKnowMore.vue';
import ImageSectionKeyboard from '@/components/sections/image/ImageSectionKeyboard.vue';
import ScreenshotCarousel from '@/components/sections/why-saio/ScreenshotCarousel.vue';
import isMobile from '@/services/mobileService';
import SaioOverallItems from './SaioOverallItems.vue';
import SaioOverallItemsMobile from './SaioOverallItemsMobile.vue';

@Component({
  components: {
    Processes,
    WantKnowMore,
    ImageSectionKeyboard,
    ScreenshootCarousel: ScreenshotCarousel,
    SaioOverallItems,
    SaioOverallItemsMobile,
  },
})
export default class WhySaio extends Vue {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  // public nextPage = 'implementations'; // TEMP
  public nextPage = 'about-saio';

  mounted() {
    (window as any).jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0,
    });
  }

  get isMobile() {
    return isMobile();
  }
}
